import React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import Compressor from 'compressorjs';

// apis
import { getUserInfo, updateUserInfo, IUpdateUserInfoForm } from '../../apis/user';
import { getDictList, IDictDataOption, uploadFile } from '../../apis/common';

// antd
import { Button, Form, message, Space, Input, Row, Col, Switch, DatePicker, Spin, Divider, Select } from 'antd';

import avatarImg from '@assets/images/register/avatar.png';
import idcardBackImg from '@assets/images/register/idcard_back.png';
import idcardFrontImg from '@assets/images/register/idcard_front.png';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;

function UserInfoFormPage() {
    const [form] = Form.useForm<IUpdateUserInfoForm>();

    const [sexList, setSexList] = useState<IDictDataOption[]>([]);


    // 获取用户信息
    useEffect(() => {
        getUserInfo().then(res => {
            let data = res.data.salesman;
            localStorage.setItem('user', JSON.stringify(data));

            setInputAvatarImg(data.avatar_link);
            setInputIdcardFrontImg(data.idcard_front_img_link);
            setInputIdcardBackImg(data.idcard_back_img_link);
            form.setFieldsValue({
                ...data,
                birthday: data.birthday ? dayjs(data.birthday) as any : undefined,
                idcard_begin_date: data.idcard_begin_date ? dayjs(data.idcard_begin_date) as any : undefined,
                idcard_expire_date: data.idcard_expire_date ? dayjs(data.idcard_expire_date) as any : undefined,
            })
            if (data.idcard_begin_date && data.idcard_expire_date) {
                form.setFieldsValue({
                    idcard_date: [dayjs(data.idcard_begin_date), dayjs(data.idcard_expire_date)]
                })
            }
        });

        getDictList(["global_sex"]).then(res => {
            setSexList(res.data.global_sex!.options);
        })
    }, []);

    // 提交
    const [submitLoading, setSubmitLoading] = useState(false);
    const submit = async () => {
        await form.validateFields();

        let rawForm = JSON.parse(JSON.stringify(form.getFieldsValue(true)));
        if (!rawForm.idcard_front_img || !rawForm.idcard_back_img) {
            message.error('请先上传身份证');
            return;
        }

        if (rawForm.idcard_begin_date) {
            rawForm['idcard_begin_date'] = dayjs(rawForm.idcard_begin_date).format('YYYY-MM-DD');
        }

        if (rawForm.idcard_expire_date) {
            rawForm['idcard_expire_date'] = dayjs(rawForm.idcard_expire_date).format('YYYY-MM-DD');
        }

        if (rawForm.birthday) {
            rawForm['birthday'] = dayjs(rawForm.birthday).format('YYYY-MM-DD');
        }

        try {
            setSubmitLoading(true);
            let res = await updateUserInfo(rawForm);
            message.success(res.msg);
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }

    }

    // 身份证有效期时间段
    const handleIdcardDateChange = (_: any, formatString: [string, string]) => {
        form.setFieldsValue({
            idcard_begin_date: formatString[0],
            idcard_expire_date: formatString[1],
        })
    };

    const resetIdcardDate = () => {
        form.setFieldsValue({
            idcard_date: undefined,
            idcard_begin_date: undefined,
            idcard_expire_date: undefined,
        })
    };

    // 上传身份证国徽面
    const [inputIdcardFrontLoading, setInputIdcardFrontLoading] = useState(false);
    const inputIdcardFrontRef = React.createRef<HTMLInputElement>();
    const [inputIdcardFrontImg, setInputIdcardFrontImg] = useState<string>();
    const uploadIdcardFront = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputIdcardFrontLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'idcard' }).then(res => {
                    setInputIdcardFrontImg(res.data.full_link);
                    form.setFieldValue('idcard_front_img', res.data.file_id);
                    // getIdcardOcrData({ fid: res.data.file_id }).then(res => {
                    //     let data = res.data;
                    //     if (data.valid_date_start && data.valid_date_end) {
                    //         form.setFieldValue('idcard_date', [dayjs(data.valid_date_start), dayjs(data.valid_date_end)]);
                    //     }
                    //     form.setFieldsValue({
                    //         idcard_begin_date: data.valid_date_start,
                    //         idcard_expire_date: data.valid_date_end,
                    //     });
                    // });
                    setInputIdcardFrontLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputIdcardFrontLoading(false);
            },
        });
    };
    // 上传身份证人像面
    const [inputIdcardBackLoading, setInputIdcardBackLoading] = useState(false);
    const inputIdcardBackRef = React.createRef<HTMLInputElement>();
    const [inputIdcardBackImg, setInputIdcardBackImg] = useState<string>();
    const uploadIdcardBack = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputIdcardBackLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'idcard' }).then(res => {
                    if ('data' in res) {
                        setInputIdcardBackImg(res.data.full_link);
                        form.setFieldValue('idcard_back_img', res.data.file_id);
                        // getIdcardOcrData({ fid: res.data.file_id }).then(res => {
                        //     let data = res.data;
                        //     form.setFieldsValue({
                        //         name: data.name,
                        //         sex: data.sex_code,
                        //         nation_id: data.nation_code,
                        //         birthday: data.birth ? dayjs(data.birth) as any : undefined,
                        //         native_address: data.address,
                        //         idcard: data.id_num,
                        //     });
                        // });
                    }
                    setInputIdcardBackLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputIdcardBackLoading(false);
            },
        });
    };

    // 上传头像
    const [inputAvatarLoading, setInputAvatarLoading] = useState(false);
    const inputAvatarRef = React.createRef<HTMLInputElement>();
    const [inputAvatarImg, setInputAvatarImg] = useState<string>();
    const uploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputAvatarLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'salesman_logo' }).then(res => {
                    setInputAvatarImg(res.data.full_link);
                    form.setFieldValue('avatar_id', res.data.file_id);
                    setInputAvatarLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputAvatarLoading(false);
            },
        });
    };

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>个人信息</div>
            </div>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                initialValues={{
                    is_idcard_long_time: false
                }}
            >
                <Row>
                    <Col xs={24} sm={24} md={11}>
                        <Form.Item noStyle>
                            <Row style={{ margin: "20px 0" }}>
                                <Col span={9}></Col>
                                <Col span={6}>
                                    <Spin spinning={inputAvatarLoading}>
                                        <img
                                            src={inputAvatarImg ?? avatarImg}
                                            style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                            alt="头像"
                                            onClick={() => inputAvatarRef.current?.click()}
                                        />
                                    </Spin>

                                    <input
                                        ref={inputAvatarRef}
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept='image/jpeg,image/jpg,image/png'
                                        onChange={uploadAvatar}
                                    />
                                </Col>
                                <Col span={9}></Col>
                            </Row>
                        </Form.Item>

                        <Form.Item
                            label="姓名"
                            name="name"
                            rules={[{ required: true, message: '请输入你的姓名' }]}
                        >
                            <Input maxLength={10} />
                        </Form.Item>

                        <Form.Item
                            label="性别"
                            name="sex"
                        >
                            <Select
                                showArrow
                                placeholder="性别"
                            >
                                {sexList.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="生日"
                            name='birthday'
                        >
                            <DatePicker
                                style={{ 'width': '100%' }}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>

                        <Form.Item
                            label="部门名称"
                            name="dept_name"
                        >
                            <Input maxLength={20} />
                        </Form.Item>

                    </Col>
                    <Col sm={0} md={2}> </Col>
                    <Col xs={24} sm={24} md={11}>
                        <Divider
                            orientation="left"
                            plain
                        >银行卡信息</Divider>

                        <Form.Item
                            label="银行名称"
                            name="bank_name"
                        >
                            <Input placeholder="银行名称" />
                        </Form.Item>

                        <Form.Item
                            label="银行卡号"
                            name="bank_no"
                        >
                            <Input
                                placeholder="银行卡号"
                                onKeyUp={(e) => { e.currentTarget.value = e.currentTarget.value.replace(/\D+/g, '') }}
                            />
                        </Form.Item>

                        <Divider
                            orientation="left"
                            plain
                        >身份证信息</Divider>

                        {/* 身份证正反面 */}
                        <Form.Item noStyle>
                            <Row style={{ margin: "20px 0" }}>
                                <Col span={5}></Col>
                                <Col span={6}>
                                    <Spin spinning={inputIdcardBackLoading}>
                                        <img
                                            src={inputIdcardBackImg ?? idcardBackImg}
                                            style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                            alt="身份证人像面"
                                            onClick={() => inputIdcardBackRef.current?.click()}
                                        />
                                    </Spin>

                                    <input
                                        ref={inputIdcardBackRef}
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept='image/jpeg,image/jpg,image/png'
                                        onChange={uploadIdcardBack}
                                    />
                                </Col>
                                <Col span={2}></Col>
                                <Col span={6}>
                                    <Spin spinning={inputIdcardFrontLoading}>
                                        <img
                                            src={inputIdcardFrontImg ?? idcardFrontImg}
                                            style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                            alt="身份证国徽面"
                                            onClick={() => inputIdcardFrontRef.current?.click()}

                                        />
                                    </Spin>
                                    <input
                                        ref={inputIdcardFrontRef}
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept='image/jpeg,image/jpg,image/png'
                                        onChange={uploadIdcardFront}
                                    />
                                </Col>
                                <Col span={5}></Col>
                            </Row>
                        </Form.Item>

                        <Form.Item
                            label="身份证是否长期"
                            name="is_idcard_long_time"
                            valuePropName="checked"
                        >
                            <Switch checkedChildren="是" unCheckedChildren="否" onChange={resetIdcardDate} />
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, curValues) =>
                                    prevValues.is_idcard_long_time !== curValues.is_idcard_long_time
                            }
                        >
                            {({ getFieldValue }) => {
                                return getFieldValue('is_idcard_long_time') === false
                                    ? <Form.Item
                                        name="idcard_date"
                                        rules={[{ required: true, message: '请选择身份证有效期' }]}
                                        label="身份证有效期"
                                        shouldUpdate
                                    >
                                        <RangePicker
                                            style={{ 'width': '100%' }}
                                            onChange={handleIdcardDateChange}
                                            format='YYYY-MM-DD'
                                            placeholder={['身份证有效期开始时间', '身份证有效期结束时间']}
                                        />
                                    </Form.Item>
                                    : <Form.Item
                                        label="身份证有效期开始时间"
                                        name='idcard_begin_date'
                                        rules={[{ required: true, message: '请选择身份证有效期开始时间' }]}>
                                        <DatePicker
                                            style={{ 'width': '100%' }}
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                            }}
                        </Form.Item>


                    </Col>
                </Row>

                <Form.Item>
                    <Space>
                        <Button type='primary' onClick={submit} loading={submitLoading}>提交表单</Button>
                    </Space>
                </Form.Item>
            </Form>
        </div >
    );
}


export default UserInfoFormPage;