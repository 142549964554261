import { Outlet } from 'react-router-dom';

// assets
import bgImg from '@assets/images/bg/bg.png';
import logo from '@assets/logo.svg';

// style
import '../login.scss';
import { Col, Row } from 'antd';


/**
 * 注册员工页
 */
function Wizard() {
    return (
        // 左右对称弹性布局
        <Row className='login-box' style={{ backgroundImage: `url(${bgImg})` }}>
            <Col xs={24} sm={24} md={18} lg={15} xl={12} className='login-box__left'>
                <div className='login-box__left__box'>
                    <div className='login-box__left__box__flex'>
                        {/* logo */}
                        <img className='login-box__left__box__logo' src={logo} alt="雄智集团" />
                        {/* title */}
                        <span className='login-box__left__box__title'>雄智劳动争议顾问平台<br />业务端</span>
                    </div>
                    {/* 间距 */}
                    <div className='login-box__left__box__padding'></div>
                    {/* 表单 */}
                    <Outlet />
                </div>
            </Col>
            <Col xs={0} sm={0} md={6} lg={9} xl={12} className='login-box__right'>
                <div className='login-box__right__copyright'>Copyright@2023 by 思博达-和谐劳务-Powered By Siboda  <a href='http://beian.miit.gov.cn/' target="_blank">粤ICP备2020131149号</a></div>
                {/* <img className='login-box__right__img' src={bgImg} alt="背景图片" /> */}
            </Col>
        </Row>
    );
}


export default Wizard;