import React, { useEffect, useState } from 'react';

// apis
import { getOrderDetail, IOrderDetail, IOrderDetailStep } from '../../apis/order';

// antd
import { Button, Card, Collapse, Descriptions, List, Modal } from 'antd';
import { DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import './detail.scss';

const { Panel } = Collapse;

function OrderDetailPage() {
    const { id } = useParams();

    // 获取用户信息
    const [detail, setDetail] = useState<IOrderDetail>();
    const [stepList, setStepList] = useState<IOrderDetailStep[]>();
    const [defaultActiveKey, setDefaultActiveKey] = useState<string>("base");
    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        getOrderDetail({ order_id: Number(id) }).then(res => {
            setDetail(res.data.detail);
            setStepList(res.data.detail.order_process_step_list);
        });
    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>项目信息</div>
            </div>

            {
                detail && stepList && defaultActiveKey ?
                    <Collapse accordion defaultActiveKey={defaultActiveKey}>
                        <Panel header="基础信息" key="base">
                            <Descriptions title={detail.title} layout="vertical">
                                <Descriptions.Item label="项目金额（元）">{detail.money}</Descriptions.Item>
                                <Descriptions.Item label="当前跟进步骤">{detail.process_title ?? "无"}</Descriptions.Item>
                                <Descriptions.Item label="附件">
                                    {
                                        detail.attach_files_info.length > 0 ?
                                            <List
                                                className='record__attachment'
                                                itemLayout="horizontal"
                                                dataSource={detail.attach_files_info}
                                                renderItem={item => (
                                                    <li>
                                                        <Card bodyStyle={{ padding: "0 10px" }}>
                                                            <div className='record__attachment__card'>
                                                                <div>{item.attach_name}</div>
                                                                <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                            </div>
                                                        </Card>
                                                    </li>
                                                )}
                                            /> : "无"
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Panel>
                        {
                            stepList?.map((step, index) => {
                                return (
                                    <Panel header={`步骤：${step.process_step_title}`} key={step.id}>
                                        <Descriptions title={step.process_step_title}>
                                            <Descriptions.Item label="步骤描述">{step.process_step_desc ?? '无'}</Descriptions.Item>
                                            <Descriptions.Item label="状态">{step.status_text}</Descriptions.Item>
                                            <Descriptions.Item label="附件">
                                                {
                                                    step.process_step_attach_files_info.length > 0 ?
                                                        <List
                                                            className='record__attachment'
                                                            itemLayout="horizontal"
                                                            dataSource={step.process_step_attach_files_info}
                                                            renderItem={item => (
                                                                <li>
                                                                    <Card bodyStyle={{ padding: "0 10px" }}>
                                                                        <div className='record__attachment__card'>
                                                                            <div>{item.attach_name}</div>
                                                                            <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                                        </div>
                                                                    </Card>
                                                                </li>
                                                            )}
                                                        /> : "无"
                                                }
                                            </Descriptions.Item>
                                        </Descriptions>
                                        {
                                            step.order_process_step_detail.map(process => (
                                                <Descriptions title={`${process.adviser.name}`}>
                                                    <Descriptions.Item label="状态">{process.status_text ?? '无'}</Descriptions.Item>
                                                    <Descriptions.Item label="步骤描述">{process.desc ?? '无'}</Descriptions.Item>
                                                    <Descriptions.Item label="附件">
                                                        {
                                                            process.attach_files_info.length > 0 ?
                                                                <List
                                                                    className='record__attachment'
                                                                    itemLayout="horizontal"
                                                                    dataSource={process.attach_files_info}
                                                                    renderItem={item => (
                                                                        <li>
                                                                            <Card bodyStyle={{ padding: "0 10px" }}>
                                                                                <div className='record__attachment__card'>
                                                                                    <div>{item.attach_name}</div>
                                                                                    <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                                                </div>
                                                                            </Card>
                                                                        </li>
                                                                    )}
                                                                /> : "无"
                                                        }
                                                    </Descriptions.Item>

                                                </Descriptions>
                                            ))
                                        }
                                    </Panel>
                                );
                            })
                        }
                    </Collapse>
                    : null
            }
        </div>
    );
}


export default OrderDetailPage;