import { axiosInstance, CommonResponse } from ".";
import { Pager } from "./common";

export interface ICompany {
    id: number,
    dept_name?: string,
    mobile?: string,
    avatar_id?: number,
    name_first_letter?: string,
    avatar_link?: string,
    company_text: string,
    company_id: number,
    real_name: string,
    company: {
        id: number,
        name: string,
        social_no: string
    }
}

export interface ICompanyList {
    list: ICompany[],
    total: number
}

export interface ICompanyListSearchForm extends Pager {
    keywords?: string
}

/**
 * 获取企业列表
 * @returns 
 */
export const getCompanyList = (data: ICompanyListSearchForm): Promise<CommonResponse<ICompanyList>> => {
    return axiosInstance.post('/salesman/company/list', data).then(res => res.data);
};


export interface ICompanyForm {
    id?: number,
    mobile: string,
    dept_name?: string,
    avatar_id?: number,
    social_no: string,
    company_name: string,
    real_name: string,
}

/**
 * 添加企业
 * @param data 
 * @returns 
 */
export const addCompany = (data: ICompanyForm): Promise<CommonResponse> => {
    return axiosInstance.post('/salesman/company/add', data).then(res => res.data);
};

/**
 * 编辑企业
 * @param data 
 * @returns 
 */
export const editCompany = (data: ICompanyForm): Promise<CommonResponse> => {
    return axiosInstance.post('/salesman/company/edit', data).then(res => res.data);
};