import { useState, useEffect } from 'react';

// antd
import { Button, Form, Input, InputNumber, message, Modal, Select, Upload, UploadFile } from 'antd';

// interface
import { FileUpload, getAllCompany, ICompanyItem, uploadFile } from '../../apis/common';
import { UploadOutlined } from '@ant-design/icons';

import { addOrder, IOrder, IOrderForm } from '../../apis/order';
import TextArea from 'antd/es/input/TextArea';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";
import { Attachment } from '../../apis/attachment';

const { Option } = Select;

function AddOrderFormModal({
    visible,
    onFinish,
    onCancel,
    data
}: {
    data?: IOrder,
    visible: boolean,
    onFinish: () => void,
    onCancel: () => void,
}) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 企业列表
    const [companyList, setCompanyList] = useState<ICompanyItem[]>([]);
    const loadAllCompany = async () => {
        try {
            let res = await getAllCompany();
            setCompanyList(res.data.list);
        } catch (e) {
            console.error(e);
        }
    }

    // 表单数据
    const [form] = Form.useForm<IOrderForm>();
    useEffect(() => {
        // 初始化
        if (visible) {
            form.resetFields();
            setAttachments([]);
            loadAllCompany();
        }
    }, [data, visible]);


    // 提交
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let res = await addOrder({
                ..._form,
                // 附件
                attach_files: attachments
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    })
            });
            message.success(res.msg);
            onFinish();
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList);
        setAttachments(info.fileList);
    }
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'order' })
            .then(res => {
                onSuccess!(res.data);
            });
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    return (
        <Modal
            width={800}
            visible={visible}
            title={`${data?.id ? '编辑' : '添加'}项目`}
            okText="保存"
            cancelText="取消"
            maskClosable={false}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        console.log(values);
                        submit();
                    })
            }}
        >
            <Form
                className='inner-page'
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="项目名称"
                    name="title"
                    rules={[{ required: true, message: '请输入项目名称' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="选择企业"
                    name="company_id"
                    rules={[{ required: true, message: '请选择企业' }]}
                >
                    <Select
                        allowClear
                        showArrow
                        placeholder="请选择企业"
                        showSearch
                        filterOption={(input, option) => {
                            console.log(input, option);
                            return (option?.children ?? '').toString().toLowerCase().includes(input.toLowerCase())
                        }
                        }
                    >
                        {companyList.map(option => (
                            <Option key={option.id} value={option.id}>{option.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="金额"
                    name="money"
                    rules={[{ required: true, message: '请填写金额' }]}
                >
                    <InputNumber addonAfter="元" precision={2} />
                </Form.Item>

                <Form.Item
                    label="备注"
                    name='remark'
                >
                    <TextArea rows={4} placeholder="请填写备注" />
                </Form.Item>

                <Form.Item
                    label="附件"
                >
                    <Upload
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                    >
                        <Button icon={<UploadOutlined />}>点击上传文件</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AddOrderFormModal;