import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import DefaultLayout from "../layout/defaultLayout";
import { NotFoundPage } from "../views/common/404";
import CompanyPage from "../views/company/company";
import HomePage from "../views/order/order";
import LoginForm from "../views/login/login";
import RegisterStaff from "../views/login/register/registerStaff";
import RegisterStaffPending from "../views/login/register/registerStaffPending";
import Wizard from "../views/login/register/wizard";
import ChangePasswordFormPage from "../views/mine/password";
import UserInfoFormPage from "../views/mine/userInfo";
import OrderListPage from "../views/order/order";
import OrderDetailPage from "../views/order/detail";
interface IRoute {
    path: string | undefined;
    name: string;
    element: JSX.Element;
    children?: IRoute[];
}

/**
 * 路由统一配置
 */
export const routes: IRoute[] = [
    {
        path: '/',
        name: '首页',
        element: <Navigate to={'/order'}></Navigate>,
    }, {
        path: '/order',
        name: '业务管理',
        element: <DefaultLayout />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <OrderListPage />,
            },
            {
                path: 'detail/:id',
                name: '项目详情',
                element: <OrderDetailPage />,
            }
        ]
    }, {
        path: '/home',
        name: '业务管理',
        element: <DefaultLayout><HomePage /></DefaultLayout>,
    }, {
        path: '/password',
        name: '密码修改',
        element: <DefaultLayout><ChangePasswordFormPage /></DefaultLayout>,
    }, {
        path: '/info',
        name: '个人信息',
        element: <DefaultLayout><UserInfoFormPage /></DefaultLayout>,
    }, {
        path: '/login',
        name: '登录',
        element: <Wizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <LoginForm />,
            },
        ]
    }, {
        path: '/register',
        name: '注册',
        element: <Wizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <RegisterStaff />,
            },
            {
                path: 'pending',
                name: '注册待审批',
                element: <RegisterStaffPending />,
            },
        ]
    }, {
        path: '/company',
        name: '企业管理',
        element: <DefaultLayout><CompanyPage /></DefaultLayout>,
    }, {
        path: '*',
        name: '无页面',
        element: <NotFoundPage />
    }
]

// 遍历路由树生成route列表
function getRouteList(routes: IRoute[] | undefined): RouteObject[] {
    if (!routes) return [];

    return routes.map(route => {
        let item: any = {
            path: route.path,
            element: route.element,
        };
        if (route.name === 'index') {
            item.index = true;
        } else {
            item.children = getRouteList(route.children);
        }
        return item;
    });
}

// 构建router
export const router = createBrowserRouter(getRouteList(routes));