import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { Pager } from "./common";
import { ICompany } from "./company";
import { IUserInfo } from "./user";

export interface IOrder {
    id: number,
    title: string,
    money: number,
    company_id: number,
    company: {
        id: number,
        name: string,
        mobile: string
    },
    process_title: string,
    status_text?: string,
    updated_at: string,
    current_process_step: {
        id: number,
        order_id: number,
        process_step_id: number,
        process_step_title: string,
        process_step_desc: string,
        process_step_is_location: boolean,
        process_step_sort: number,
        process_step_attach_files: number[],
        status_text?: string,
        process_step_attach_files_info: Attachment[]
    },
    current_process_step_adviser: IUserInfo[],
}

export interface IOrderList {
    list: IOrder[],
    total: number
}

export interface IOrderListSearchForm extends Pager {
    company_name?: string,
    keywords?: string
}

/**
 * 获取业务列表
 * @returns 
 */
export const getOrderList = (data: IOrderListSearchForm): Promise<CommonResponse<IOrderList>> => {
    return axiosInstance.post('/salesman/order/list', data).then(res => res.data);
};


export interface IOrderForm {
    title: string,
    company_id: number,
    money: number,
    remark?: string,
    attach_files: number[]
}

/**
 * 添加业务订单
 * @param data 
 * @returns 
 */
export const addOrder = (data: IOrderForm): Promise<CommonResponse> => {
    return axiosInstance.post('/salesman/order/create', data).then(res => res.data);
};


export interface IOrderDetail {
    id: number,
    title: string,
    money: number,
    process_id: number,
    process_title?: string,
    attach_files: number[],
    attach_files_info: Attachment[],
    remark: string,
    order_process_step_list: IOrderDetailStep[],
    current_step_adviser_list: IUserInfo[],
    status_text?: string,
}

export interface IOrderDetailStep {
    id: number,
    order_id: number,
    status: number,
    status_text: string,
    process_step_title: "热身准备",
    process_step_desc?: string,
    process_step_is_location: false,
    process_step_attach_files: [],
    process_step_attach_files_info: Attachment[],
    order_process_step_detail: [
        {
            id: number,
            order_process_step_id: number,
            order_id: number,
            adviser_id: number,
            time_consuming: number,
            execute_begin_at: string,
            execute_end_at: string,
            lat?: number,
            lng?: number,
            address?: string,
            desc: string,
            attach_files: number[],
            status_text?: string,
            time_consuming_text: string,
            adviser: IUserInfo,
            attach_files_info: Attachment[]
        }
    ]
}

export interface IOrderDetailRes {
    detail: IOrderDetail,
}

/**
 * 获取业务详情
 * @param data 
 * @returns 
 */
export const getOrderDetail = (data: { order_id: number }): Promise<CommonResponse<IOrderDetailRes>> => {
    return axiosInstance.post('/salesman/order/detail', data).then(res => res.data);
};