import React from 'react';
import { ChangeEvent, useState } from 'react';
import Compressor from 'compressorjs';
import { useNavigate } from 'react-router';

// assets
import avatarImg from '@assets/images/register/avatar.png';

// antd
import { Button, Col, Form, Input, message, Row, Spin } from 'antd';

// interface

// apis
import { uploadFile } from '../../../apis/common';

// style
import '../login.scss';
import './register.scss';
import { IRegisterForm, register } from '../../../apis/user';

/**
 * 注册员工页
 */
function RegisterStaff() {
    // 路由
    const navigate = useNavigate();

    // 表单实例
    const [form] = Form.useForm<IRegisterForm>();

    // 表单提交
    const [submitLoading, setSubmitLoading] = useState(false);
    const onSubmit = async (values: any) => {
        let _form = form.getFieldsValue(true);

        setSubmitLoading(true);
        console.log('Received values of form: ', _form);
        try {
            let res = await register({
                ..._form,
            });
            message.success(res.msg);
            navigate('/register/pending', { replace: true });
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }
    };

    // 上传头像
    const [inputAvatarLoading, setInputAvatarLoading] = useState(false);
    const inputAvatarRef = React.createRef<HTMLInputElement>();
    const [inputAvatarImg, setInputAvatarImg] = useState<string>();
    const uploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputAvatarLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'salesman_logo' }).then(res => {
                    setInputAvatarImg(res.data.full_link);
                    form.setFieldValue('avatar_id', res.data.file_id);
                    setInputAvatarLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputAvatarLoading(false);
            },
        });
    };

    return (
        // 流式布局
        <div className='login-form register'>
            {/* 标题（登录方式） */}
            <div className='login-form__title'>注册</div>

            {/* 表单 */}
            <Form
                form={form}
                name="registerForm"
                className="login-form__form"
                layout='vertical'
                onFinish={onSubmit}
            >
                {/* 头像 */}
                <Form.Item noStyle>
                    <Row style={{ margin: "20px 0" }}>
                        <Col xs={2} sm={3} md={4} lg={5} xl={6}></Col>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12}>
                            <Spin spinning={inputAvatarLoading}>
                                <img
                                    src={inputAvatarImg ?? avatarImg}
                                    style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                    alt="头像"
                                    onClick={() => inputAvatarRef.current?.click()}
                                />
                            </Spin>

                            <input
                                ref={inputAvatarRef}
                                style={{ display: 'none' }}
                                type="file"
                                accept='image/jpeg,image/jpg,image/png'
                                onChange={uploadAvatar}
                            />
                        </Col>
                    </Row>
                </Form.Item>

                {/* 姓名 */}
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: '请输入姓名' }]}
                >
                    <Input placeholder="姓名" maxLength={10} />
                </Form.Item>

                {/* 身份证号 */}
                <Form.Item
                    name="idcard"
                    rules={[{ required: true, message: '请输入身份证号' }]}
                >
                    <Input placeholder="身份证号" maxLength={18} />
                </Form.Item>

                {/* 企业名称 */}
                <Form.Item
                    name="company_name"
                >
                    <Input placeholder="企业名称" maxLength={20} />
                </Form.Item>

                {/* 部门名称 */}
                <Form.Item
                    name="dept_name"
                >
                    <Input placeholder="部门名称" maxLength={20} />
                </Form.Item>

                {/* 登录按钮 */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={submitLoading} block className="login-form__form__button">
                        提交审核
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}


export default RegisterStaff;