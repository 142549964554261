import React, { useState, useEffect, ChangeEvent } from 'react';
import Compressor from 'compressorjs';

// antd
import { Col, Divider, Form, Input, message, Modal, Row, Spin } from 'antd';

// interface
import { addCompany, editCompany, ICompany, ICompanyForm } from '../../apis/company';
import { uploadFile } from '../../apis/common';

import pickLogoImg from '@assets/images/register/pick_logo.png';

function AddEditCompanyFormModal({
    visible,
    onFinish,
    onCancel,
    data
}: {
    data?: ICompany,
    visible: boolean,
    onFinish: () => void,
    onCancel: () => void,
}) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<ICompanyForm>();
    useEffect(() => {
        // 初始化
        if (visible) {
            if (data) {
                setInputAvatarImg(data.avatar_link);
                let newData = {
                    ...data,
                    company_name: data.company.name,
                    social_no: data.company.social_no
                }
                form.setFieldsValue(newData);
            } else {
                setInputAvatarImg(undefined);
                form.resetFields();
            }
        }
    }, [data, visible]);


    // 提交
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let res = await (data ? editCompany(_form) : addCompany(_form));
            message.success(res.msg);
            onFinish();
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 上传头像
    const [inputAvatarLoading, setInputAvatarLoading] = useState(false);
    const inputAvatarRef = React.createRef<HTMLInputElement>();
    const [inputAvatarImg, setInputAvatarImg] = useState<string>();
    const uploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputAvatarLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'company_logo' }).then(res => {
                    setInputAvatarImg(res.data.full_link);
                    form.setFieldValue('avatar_id', res.data.file_id);
                    setInputAvatarLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputAvatarLoading(false);
            },
        });
    };

    return (
        <Modal
            visible={visible}
            title={`${data?.id ? '编辑' : '创建'}企业`}
            okText="保存"
            cancelText="取消"
            maskClosable={false}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        console.log(values);
                        submit();
                    })
            }}
        >
            <Form
                className='inner-page'
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                {/* <Row> */}
                {/* <Col xs={24} sm={24} md={11}> */}
                <Form.Item
                    label="企业名称"
                    name="company_name"
                    rules={[{ required: true, message: '请输入企业名称' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="企业社会信用代码"
                    name="social_no"
                    rules={[{ required: true, message: '请输入企业社会信用代码' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="手机号"
                    name="mobile"
                    rules={[{ required: true, message: '请输入正确的手机号', pattern: new RegExp(/^1(2|3|4|5|6|7|8|9)\d{9}$/, "g") }]}
                >
                    <Input type={'tel'} maxLength={11} />
                </Form.Item>

                <Form.Item
                    label="联系人"
                    name="real_name"
                    rules={[{ required: true, message: '请输入联系人' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="部门名称"
                    name="dept_name"
                >
                    <Input placeholder="请输入部门名称" />
                </Form.Item>
                {/* </Col> */}
                {/* <Col sm={0} md={2}> </Col>
                    <Col xs={24} sm={24} md={11}>
                        <Divider
                            orientation="left"
                            plain
                        >企业logo</Divider>

                        <Form.Item noStyle>
                            <Row style={{ margin: "20px 0" }}>
                                <Col span={8}></Col>
                                <Col span={8}>
                                    <Spin spinning={inputAvatarLoading}>
                                        <img
                                            src={inputAvatarImg ?? pickLogoImg}
                                            style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                            alt="logo"
                                            onClick={() => inputAvatarRef.current?.click()}
                                        />
                                    </Spin>

                                    <input
                                        ref={inputAvatarRef}
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept='image/jpeg,image/jpg,image/png'
                                        onChange={uploadAvatar}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col> */}
                {/* </Row> */}
            </Form>
        </Modal>
    );
}

export default AddEditCompanyFormModal;