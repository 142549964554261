import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'ahooks';

// apis
import { getCompanyList, ICompany, ICompanyListSearchForm } from '../../apis/company';

// interface

// antd
import { Space, Table, Dropdown, Menu, Button, Modal, Input, Grid, Card, Pagination, Empty } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import AddEditCompanyFormModal from './addEditCompanyFormModal';

const { Column } = Table;
const { useBreakpoint } = Grid;

function CompanyPage() {
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<ICompanyListSearchForm>({
        page: 1,
        size: 10,
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(ICompany)[]>([]);
    const [mListData, setMListData] = useState<(ICompany)[]>([]);
    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: ICompanyListSearchForm) => {
        let _form = newForm ?? form;
        setLoading(true);
        try {
            let res = await getCompanyList(_form);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm: ICompanyListSearchForm = {
            page: 1,
            size: 10,
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData();
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    // 添加编辑弹窗
    const [addEditModalForm, setAddEditModalForm] = useState<{
        isModalVisible: boolean,
        data?: ICompany
    }>();
    const toAdd = () => {
        setAddEditModalForm({
            isModalVisible: true,
            data: undefined
        })
    };
    const toEdit = (data: ICompany) => {
        setAddEditModalForm({
            isModalVisible: true,
            data: data
        })
    };


    return (
        <div className='address inner-page'>
            <div className='search'>
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="关键词搜索"
                />

                <Button
                    type="primary"
                    className="search__last-button"
                    icon={<PlusOutlined />}
                    onClick={toAdd}
                >
                    创建企业
                </Button>

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Card className='m-card' onClick={() => toEdit(item)}>
                                    <div className='m-card_item'>
                                        <div>企业名称：</div>
                                        <div>{item.company?.name ?? '无'}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>部门：</div>
                                        <div>{item.dept_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>联系人：</div>
                                        <div>{item.real_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>账号：</div>
                                        <div>{item.mobile}</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>

                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="企业名称" dataIndex={["company", "name"]} key="name" />
                        <Column title="部门" dataIndex="dept_name" key="dept_name" />
                        <Column title="联系人" dataIndex="real_name" key="contact" />
                        <Column title="账号" dataIndex="mobile" key="mobile" />
                        <Column
                            title="操作"
                            key="action"
                            render={(_: any, record: ICompany) => (
                                <Space>
                                    <Dropdown.Button
                                        size='small'
                                        type="primary"
                                        // loading={}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'edit',
                                                        label: (
                                                            <div>编辑</div>
                                                        ),
                                                        onClick: () => toEdit(record),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 添加编辑弹窗 */}
            {
                addEditModalForm
                    ? <AddEditCompanyFormModal
                        data={addEditModalForm.data}
                        visible={addEditModalForm.isModalVisible}
                        onFinish={() => {
                            setAddEditModalForm({
                                ...addEditModalForm,
                                isModalVisible: false
                            })
                            resetData();
                        }}
                        onCancel={() => {
                            setAddEditModalForm({
                                ...addEditModalForm,
                                isModalVisible: false
                            })
                        }}
                    /> : <></>
            }
        </div>
    );
}

export default CompanyPage;